<template>
  <v-form ref="m_formElement" :disabled="bSubmitting" enctype="multipart/form-data">
    <v-row>
      <v-col cols="12" :class="`col-${strColBreakpoint}-${iColBreakpoint}`">
        <div :class="m_strLabelClass">{{ m_arrInputLabels[0] }}</div>
        <base-text-field
          outlined
          :name="m_arrInputLabels[0]"
          required
          color="black"
          @change="changeName($event)"
        />

        <div :class="m_strLabelClass">{{ m_arrInputLabels[1] }}</div>
        <base-text-field
          outlined
          :name="m_arrInputLabels[1]"
          required
          color="black"
          @change="changeMobilePhone($event)"
        />

        <div :class="m_strLabelClass">{{ m_arrInputLabels[2] }}</div>
        <base-text-field
          outlined
          :name="m_arrInputLabels[2]"
          email
          required
          color="black"
          @change="changeEmail($event)"
        />

        <div :class="m_strLabelClass">{{ m_strFileUploadLabel }}</div>
        <k-d-a-droppable-file-upload
          :b-first-try-submit="m_bFirstTrySubmit"
          @selectFile="selectFile($event)"
        />

        <p :class="`kda-ts-${g_bLowerBr ? '12' : '14'}pt nunito wt-bold font-italic`">
          {{ m_strDateInfo }}
        </p>

        <v-btn
          color="main-r-450"
          block
          large
          :class="`white--text text-capitalize kda-ts-16pt nunito wt-bold mt-${
            g_bLowerBr ? '10' : '15'
          }`"
          :disabled="bSubmitting"
          @click="submitApplication"
        >
          {{ m_strButtonLabel }}
          <v-icon right> mdi-chevron-right </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import * as moment from 'moment';

export default {
  name: 'KDACareerApplicationForm',

  components: {
    KDADroppableFileUpload: () => import('@/components/KDADroppableFileUpload')
  },

  mixins: [BaseBreakpoint],

  props: {
    strColBreakpoint: {
      type: String,
      default: 'md'
    },
    iColBreakpoint: {
      type: [Number, String],
      default: 6
    },
    bSubmitting: {
      type: Boolean,
      default: false
    },
    tDateClosing: {
      type: Date,
      default: () => new Date()
    }
  },

  data() {
    return {
      m_bFirstTrySubmit: false
    };
  },

  computed: {
    m_strButtonLabel() {
      return this.$vuetify.lang.t('$vuetify.kda.careers.details.join');
    },
    m_strDateInfo() {
      return `${this.$vuetify.lang.t('$vuetify.kda.careers.details.before')} ${moment(
        this.tDateClosing
      ).format('DD MMMM YYYY')}`;
    },
    m_strLabelClass() {
      return `kda-ts-16pt nunito wt-semibold main-bw-400--text`;
    },
    m_arrInputLabels() {
      const result = [];

      for (let i = 1; i <= 3; i++) {
        result.push(this.$vuetify.lang.t(`$vuetify.kda.careers.details.input${i}`));
      }

      return result;
    },
    m_strFileUploadLabel() {
      return this.$vuetify.lang.t('$vuetify.kda.careers.details.cv');
    }
  },

  methods: {
    selectFile(selectedFile) {
      if (selectedFile) {
        this.$emit('selectFile', selectedFile);
      }
    },
    changeName(applicantName) {
      if (applicantName) {
        this.$emit('changeName', applicantName);
      }
    },
    changeEmail(applicantEmail) {
      if (applicantEmail) {
        this.$emit('changeEmail', applicantEmail);
      }
    },
    changeMobilePhone(applicantMobilePhone) {
      if (applicantMobilePhone) {
        this.$emit('changeMobilePhone', applicantMobilePhone);
      }
    },
    submitApplication() {
      this.m_bFirstTrySubmit = true;
      const isValid = this.$refs.m_formElement.validate();
      this.$emit('submitApplication', isValid);
    }
  }
};
</script>
